import React, {useState} from 'react'
import clsx from 'clsx'

import RamblerTopline from '@rambler-components/topline'
import {Geo} from 'common/components/geo'
import {Ad} from 'common/components/ad'
import {Popup} from 'common/components/popup'
import {AD_TPLNPROMO_ID} from 'common/constants/ad'

import '@rambler-components/topline/styles.css'
import styles from './styles.module.css'

interface ToplineDesktopProps {
  adParams?: {adTransitionCounter: number; routeName: string}
}

export const ToplineDesktop: React.FC<ToplineDesktopProps> = ({adParams}) => {
  const [isGeoOpened, setGeoOpened] = useState(false)

  return (
    <div className={clsx(styles.header, 'ad_branding_header')}>
      <RamblerTopline
        promoAd={
          adParams ? <Ad id={AD_TPLNPROMO_ID} adParams={adParams} /> : null
        }
        onOpenGeo={() => setGeoOpened(true)}
        projectCode="tv"
        projectName="Телепрограмма"
        withExchangeRates
        withWeather
      />
      <Popup
        width={560}
        isOpen={isGeoOpened}
        onClose={() => setGeoOpened(false)}>
        <Geo onClose={() => setGeoOpened(false)} />
      </Popup>
    </div>
  )
}
