/**
 * First-party
 */
export const PUBLISHER_SCOPE = '.rambler.ru'

/**
 * Ids рекламных мест в десктопе
 */
export const DESKTOP_PADID = 432426490
export const AD_TPLNPROMO_ID = 579499148
export const AD_BILLBOARD_ID = 432426494
export const AD_PROMO_DESKTOP_ID = 579501191
export const AD_240X400_ID = 432426500
export const AD_SUPERFOOTER_ID = 432426502
export const AD_CONTEXT_ID = 579497986
export const AD_SHOWUP_ID = 579503432

/**
 * Ids рекламных мест в мобиле
 */
export const MOBILE_PADID = 579497972
export const AD_PROMO_MOBIBLE_ID = 579501193
export const AD_TOPBANNER_ID = 579497988
export const AD_CONTENT1_ID = 579497990
export const AD_CONTENT2_ID = 579499150
export const AD_CONTENT3_ID = 579499152
export const AD_CONTENT4_ID = 579499154
export const AD_FOOTER_ID = 579497992
export const AD_RICH_ID = 579503452

const DEFAULT_PUIDS: {puid31: string} = {
  puid31: 'tv.rambler'
}

export const PUIDS_BY_PAGES: Record<
  string,
  typeof DEFAULT_PUIDS & {puid6: string; puid18: string}
> = {
  main: {
    ...DEFAULT_PUIDS,
    puid6: 'TVRAMBLERRU_MAIN',
    puid18: 'TVRAMBLERRU_MAIN_MAIN'
  },
  channel: {
    ...DEFAULT_PUIDS,
    puid6: 'TVRAMBLERRU_CHANNEL',
    puid18: 'TVRAMBLERRU_CHANNEL_MAIN'
  },
  favorite: {
    ...DEFAULT_PUIDS,
    puid6: 'TVRAMBLERRU_FAVORITE',
    puid18: 'TVRAMBLERRU_FAVORITE_MAIN'
  },
  premiere: {
    ...DEFAULT_PUIDS,
    puid6: 'TVRAMBLERRU_PREMIERE',
    puid18: 'TVRAMBLERRU_PREMIERE_MAIN'
  },
  show: {
    ...DEFAULT_PUIDS,
    puid6: 'TVRAMBLERRU_SHOW',
    puid18: 'TVRAMBLERRU_SHOW_MAIN'
  }
}
