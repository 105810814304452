import React from 'react'
import {observer} from 'mobx-react-lite'
import clsx from 'clsx'

import {AdProvider} from '@rambler-components/ad'
import type {ChannelCategory} from 'types/category'
import type {Runtime} from 'types/runtime'
import type {City} from 'types/cities'

import {
  DESKTOP_PADID,
  PUBLISHER_SCOPE,
  AD_BILLBOARD_ID,
  AD_SHOWUP_ID
} from 'common/constants/ad'

import {LayoutCommon, type LayoutProps} from 'common/components/layout'
import {ToplineDesktop} from 'desktop/components/topline'
import {Ad} from 'common/components/ad'
import {CategoryNavigationDesktop} from 'desktop/components/category-navigation'
import {Footer} from 'common/components/footer'

import styles from './styles.module.css'

interface LayoutDesktopProps {
  runtime: Runtime
  categories: ChannelCategory[]
  routeParams: {city: City; date: Date}
  adParams: {adTransitionCounter: number; routeName: string}
  children: React.ReactNode
}

export const LayoutDesktop: React.FC<LayoutDesktopProps> = ({
  runtime,
  categories,
  routeParams,
  adParams,
  children
}) => (
  <AdProvider
    padId={DESKTOP_PADID}
    publisherId={runtime.ruid}
    publisherScope={PUBLISHER_SCOPE}>
    <ToplineDesktop adParams={adParams} />
    <div className={styles.billboardAd}>
      <Ad id={AD_BILLBOARD_ID} className={styles.banner} adParams={adParams} />
    </div>
    <Ad id={AD_SHOWUP_ID} adParams={adParams} />
    <div className={clsx(styles.content, 'ad_branding_main')}>
      <CategoryNavigationDesktop
        categories={categories}
        routeParams={routeParams}
        top100={`${adParams.routeName}_page`}
      />
      <main className={styles.main}>{children}</main>
    </div>
    <Footer />
  </AdProvider>
)

export const LayoutCommonDesktop: React.FC<LayoutProps> = observer(
  ({runtime, stores, error, children}) => {
    return (
      <LayoutCommon runtime={runtime} error={error} stores={stores}>
        <LayoutDesktop
          runtime={runtime}
          categories={stores.categoryStore.categories}
          routeParams={stores.routeParamsStore.routeParamsForPage}
          adParams={stores.routeParamsStore.routeParamsForAd}>
          {children}
        </LayoutDesktop>
      </LayoutCommon>
    )
  }
)
